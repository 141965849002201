import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";

function Page() {
  return (
    <div className="flex flex-col h-screen justify-between">
      <div className="mx-6 mb-auto lg:mx-32 ">
        <Header />
        <main style={{ fontSize: 24 }}>
          <h1 className="text-4xl md:text-5xl font-bold mb-4 mt-4">
            Οικονομικές Καταστάσεις
          </h1>
          Πρόσβαση στα{" "}
          <a
            href="https://drive.google.com/drive/folders/1nKBweldHqDIDwL-9Pzm-JHkWXFhgsvqw?usp=sharing"
            className="underline"
          >
            στοιχεία
          </a>
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default Page;
